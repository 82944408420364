import { Injectable } from '@angular/core';
import type { GraphQLParams, Tool } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  tools: { items: Tool[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class ToolsGQL extends Query<Response, GraphQLParams<'tool'>> {
  document = gql(config.tool.query);
}
