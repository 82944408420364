import { Injectable } from '@angular/core';
import type { GraphQLParams, Line } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  lines: { items: Line[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class LinesGQL extends Query<Response, GraphQLParams<'line'>> {
  document = gql(config.line.query);
}
