import { Injectable } from '@angular/core';
import type { EquipmentGroup, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  equipmentGroups: { items: EquipmentGroup[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentGroupsGQL extends Query<Response, GraphQLParams<'equipmentGroup'>> {
  document = gql(config.equipmentGroup.query);
}
