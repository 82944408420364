import { Injectable } from '@angular/core';
import type { GraphQLParams, WorkProcedure } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  workProcedures: { items: WorkProcedure[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class WorkProceduresGQL extends Query<
  Response,
  GraphQLParams<'workProcedure'>
> {
  document = gql(config.workProcedure.query);
}
