<div
  mat-dialog-content
  class="w-100 h-100 d-flex flex-column container gap-3 lookup-container"
  *transloco="let t"
>
  <h5 class="lookup-title" *ngIf="!inputEntity">
    {{ t(lookupTitle || '') || 'Title' }}
  </h5>

  <dx-data-grid
    #lookUpGrid
    *ngIf="delayRender; else placeholder"
    [@inOutAnimation]="delayRender"
    id="lookUpGrid"
    class="flex-1 overflow-hidden"
    [class.pt-4]="useGraphQL"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    (onSelectionChanged)="selectRow($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    [keyExpr]="keyExpression"
    [hoverStateEnabled]="true"
    [columnAutoWidth]="true"
    [columnResizingMode]="'widget'"
  >
    <dxo-selection
      [showCheckBoxesMode]="checkBoxesMode"
      mode="multiple"
    ></dxo-selection>
    <dxo-paging [pageSize]="10" [enabled]="!useGraphQL"></dxo-paging>
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[10, 25, 50, 100]"
      (textChange)="searchChange($any($event))"
    ></dxo-pager>
    <dxo-search-panel
      [visible]="!useGraphQL"
      [placeholder]="t('searchKey')"
    ></dxo-search-panel>
    <dxo-filter-row [visible]="useGraphQL"></dxo-filter-row>

    <dxi-column
      *ngFor="let col of columnNames"
      [dataField]="col.fieldName"
      [dataType]="col.type"
      [allowSorting]="!useGraphQL"
      (selectedFilterOperationChange)="
        onUpdateFilterOperation(col, $event || '')
      "
      [filterValue]="
        paginationFilter.get($any(col.filterKey || col.fieldName))?.value
      "
      (filterValueChange)="onUpdateFilterValue(col, $event)"
      [caption]="t(col.displayName || '')"
    ></dxi-column>
  </dx-data-grid>

  <ng-template #placeholder>
    <div class="d-flex justify-content-center align-items-center h-100 flex-1">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <mat-paginator
    *ngIf="useGraphQL"
    style="background-color: transparent"
    [length]="pagination.totalCount"
    [pageSize]="pagination.pageSize"
    [pageSizeOptions]="pagination.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="onPaginationChanging($event)"
  >
  </mat-paginator>
</div>
