import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Request } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  requests: { items: Request[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class RequestsGQL extends Query<Response, GraphQLParams<'request'>> {
  document = gql(config.request.query);
}

@Injectable({
  providedIn: 'root',
})
export class RequestsCountGQL extends Query<
  Response & { requestsCount: number },
  GraphQLParams<'request'>
> {
  document = gql(config.request.query);
}
