import { Injectable } from '@angular/core';
import type { GraphQLParams, Tool, WorkOrderTemplate } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  workOrderTemplates: { items: WorkOrderTemplate[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrderTemplatesGQL extends Query<
  Response,
  GraphQLParams<'workOrderTemplate'>
> {
  document = gql(config.workOrderTemplate.query);
}
