import { Injectable } from '@angular/core';
import type { Area, BinContent, GraphQLParams, MainCode } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  mainCodes: { items: MainCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class MainCodesGQL extends Query<Response, GraphQLParams<'mainCode'>> {
  document = gql(config.mainCode.query);
}
