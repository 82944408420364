import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, OrderType } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  orderTypes: { items: OrderType[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class OrderTypesGQL extends Query<Response, GraphQLParams<'orderType'>> {
  document = gql(config.orderType.query);
}
