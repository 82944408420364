/* eslint-disable no-console */
// @ts-nocheck
import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  Employee,
  EmployeeAbsenceBank,
  EmployeeAbsenceRequestPost,
  EmployeeAbsenceSched,
  EmployeeHolidaySched,
  EmployeeList,
  EmployeeScheduleLineDetail,
  TimeZoneOffset,
  TimesheetHeader,
  TimesheetHeaderPostReturn,
  TimesheetLine,
} from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TsWorkerFunctions } from '@shared/workers/ts-worker-functions';
import { WorkerService } from '@shared/workers/worker.service';
import {
  AddAbsenceRequest,
  AddTaEmployee,
  AddTimeSheetHeader,
  BatchUpdateAbsenceStatus,
  GetBanksOfHours,
  GetEmployeeSchedule,
  GetSchedule,
  GetScheduleTemplate,
  GetTaEmployees,
  GetTechTimeSheetHeaders,
  GetTimeSheetHeaders,
  PostJournalLines,
  UpdateAbsenceRequest,
} from '@stores-actions/time-and-attendance.action';
import { ScheduleDetail } from '@stores-models/schedule-detail';
import { TimeAndAttendanceStoreService } from '@stores-services/time-and-attendance-store.service';

import { AuthState } from './authentication.state';

export class TimeAndAttendanceStateModel {
  employeeSchedule: EmployeeScheduleLineDetail[] = [];
  schedule: EmployeeScheduleLineDetail[] = [];
  banksOfHours: EmployeeAbsenceBank[] = [];
  timesheetHeaders: TimesheetHeader[] = [];
  selectedTimesheetHeader: TimesheetHeader | null = null;
  timesheetLines: TimesheetLine[] = [];
  initialized = false;
  scheduleInitialized = false;
  bankInitialized = false;

  // New Schedule
  absences: EmployeeAbsenceSched[] = [];
  employees: EmployeeList[] = [];
  holidays: EmployeeHolidaySched[] = [];
  generatedSchedule: ScheduleDetail[] = [];
  scheduleTemplate: ScheduleDetail[] = [];
  timeZoneOffset: TimeZoneOffset[] = [];
  initState: Record<string, any> = {};

  calendarStartDate: Date | null = null;
}

/**
 * Purchase Order metadata and action mappings.
 *
 * @todo Add the missing endpoints from API (add, update, delete) & Update transloco messages.
 */
@State<TimeAndAttendanceStateModel>({
  name: 'timeAndAttendance',
  defaults: {
    employeeSchedule: [],
    schedule: [],
    timesheetHeaders: [],
    selectedTimesheetHeader: null,
    timesheetLines: [],
    banksOfHours: [],
    initialized: false,
    scheduleInitialized: false,
    bankInitialized: false,

    // New Schedule
    employees: [],
    scheduleTemplate: [],
    generatedSchedule: [],
    absences: [],
    holidays: [],
    timeZoneOffset: [],
    calendarStartDate: null,
    initState: {
      employee: false,
      scheduleTemplate: false,
      generatedEmployeeSchedule: false,
      generatedSchedule: false,
      start: new Date(),
      end: new Date(),
    },
  },
})
@Injectable()
export class TimeAndAttendanceState {
  //Temporary variables for Date and Time
  tempStartDate = subWeeks(startOfWeek(new Date()), 4);
  tempEndDate = addWeeks(endOfWeek(new Date()), 4);

  constructor(
    private timeAndAttendanceStoreService: TimeAndAttendanceStoreService,
    private store: Store,
    private workerService: WorkerService,
    private apollo: Apollo
  ) {}

  static getEmployeeScheduleType(
    type:
      | 'Regular Work'
      | 'Scheduled Break'
      | 'Holiday'
      | 'Vacation'
      | 'Absence',
    employeeNo: string
  ): any {
    return createSelector(
      [TimeAndAttendanceState],
      (state: TimeAndAttendanceStateModel) =>
        state.generatedSchedule.filter(
          (x) => x.timeEntryType === type && x.employeeNo === employeeNo
        )
    );
  }

  static getTechTimesheetHeadersByEmployee(
    employeeNo: string
  ): (state: TimeAndAttendanceStateModel) => TimesheetHeader[] {
    return createSelector(
      [TimeAndAttendanceState],
      (state: TimeAndAttendanceStateModel) =>
        state.timesheetHeaders.filter((x) => x.employee_No === employeeNo)
    );
  }

  static getEmployeeSchedule(
    employeeNo: string
  ): (state: TimeAndAttendanceStateModel) => ScheduleDetail[] {
    return createSelector(
      [TimeAndAttendanceState],
      (state: TimeAndAttendanceStateModel) =>
        state.generatedSchedule.filter((x) => x.employeeNo === employeeNo)
    );
  }

  @Selector()
  static getGeneratedSchedule(
    state: TimeAndAttendanceStateModel
  ): ScheduleDetail[] {
    return state.generatedSchedule;
  }

  @Selector()
  static getInitStatus(state: TimeAndAttendanceStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  static getBanksOfHours(
    state: TimeAndAttendanceStateModel
  ): EmployeeAbsenceBank[] {
    return state.banksOfHours;
  }

  @Selector()
  static getTimesheetHeaders(
    state: TimeAndAttendanceStateModel
  ): TimesheetHeader[] {
    return state.timesheetHeaders;
  }

  @Selector()
  static getSelectedTimesheetHeader(
    state: TimeAndAttendanceStateModel
  ): TimesheetHeader | null {
    return state.selectedTimesheetHeader;
  }

  @Selector()
  static getAbsences(
    state: TimeAndAttendanceStateModel
  ): EmployeeAbsenceSched[] {
    return state.absences;
  }

  @Selector()
  static getHolidays(
    state: TimeAndAttendanceStateModel
  ): EmployeeHolidaySched[] {
    return state.holidays;
  }

  @Selector()
  static getEmployees(state: TimeAndAttendanceStateModel): Employee[] {
    return state.employees;
  }

  @Selector()
  static getScheduleTemplate(
    state: TimeAndAttendanceStateModel
  ): ScheduleDetail[] {
    return state.scheduleTemplate;
  }

  @Selector()
  static getCalendarStartDate(state: TimeAndAttendanceStateModel): Date | null {
    return state.calendarStartDate;
  }

  @Action(GetScheduleTemplate, { cancelUncompleted: true })
  getScheduleTemplate(
    { getState, setState }: StateContext<TimeAndAttendanceStateModel>,
    { bustCache }: GetScheduleTemplate
  ): Observable<any> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    if (state.initState['scheduleTemplate'] && !bustCache)
      return of(state.generatedSchedule);
    return this.timeAndAttendanceStoreService
      .getGeneratedSchedule(company)
      .pipe(
        tap(
          (result: {
            schedule: ScheduleDetail[];
            calendarStartDate: Date | null;
          }) => {
            state = getState();
            setState({
              ...state,
              scheduleTemplate: result.schedule,
              calendarStartDate: result.calendarStartDate
                ? new Date(result.calendarStartDate)
                : null,
              initState: {
                ...state.initState,
                scheduleTemplate: true,
              },
            });
          }
        )
      );
  }

  @Action(GetEmployeeSchedule, { cancelUncompleted: true })
  getEmployeeSchedule(
    { getState, setState, dispatch }: StateContext<TimeAndAttendanceStateModel>,
    { start, end }: GetEmployeeSchedule
  ): Observable<any> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    const emp = this.store.selectSnapshot(AuthState.getEmployeeNo);

    let state = getState();
    if (state.initState['generatedEmployeeSchedule'])
      return of(state.generatedSchedule);
    return forkJoin([
      dispatch(new GetScheduleTemplate()),
      this.timeAndAttendanceStoreService.fetchEmployeeScheduleData(
        company,
        emp
      ),
    ]).pipe(
      tap(([_, [emps, hols, vacs, tz]]) => {
        state = getState();
        const employee = emps.find((x) => x.employeeNo === emp);
        if (!employee) throw new Error('Employee not found');

        const template = state.scheduleTemplate.filter(
          (x) => x.employeeGroupCode === employee.employeeGroupCode
        );

        if (template.length === 0)
          throw new Error(
            `T&A Template is not found for employee: ${emp} with group: ${employee.employeeGroupCode}`
          );

        // Multi threading not required for one user.
        const generatedEmployeeSchedule =
          TsWorkerFunctions.generateSingleEmployeeSchedule(
            employee,
            template,
            hols,
            vacs,
            start,
            end,
            tz
          );

        const holidays = [...state.holidays].filter(
          (x) => x.employeeNo !== emp
        );
        holidays.push(...hols);
        const absences = [...state.absences].filter(
          (x) => x.employeeNo !== emp
        );
        absences.push(...vacs);
        const employees = [...state.employees].filter(
          (x) => x.employeeNo !== emp
        );
        employees.push(...emps);
        const generatedSchedule = [...state.generatedSchedule].filter(
          (x) => x.employeeNo !== emp
        );
        generatedSchedule.push(...generatedEmployeeSchedule);

        setState({
          ...state,
          generatedSchedule,
          holidays,
          absences,
          employees,
          initState: {
            ...state.initState,
            generatedEmployeeSchedule: true,
            start,
            end,
          },
        });
      })
    );
  }

  //TODO TA - Need to filter out the previous dates stuff and add the new dates
  @Action(GetSchedule, { cancelUncompleted: true })
  getSchedule(
    { getState, setState, dispatch }: StateContext<TimeAndAttendanceStateModel>,
    { start, end, bustCache }: GetSchedule
  ): Observable<any> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);

    let state = getState();
    if (state.initState['generatedSchedule'] && !bustCache)
      return of(state.generatedSchedule);
    return forkJoin([
      dispatch(new GetScheduleTemplate()),
      this.timeAndAttendanceStoreService.fetchEmployeeScheduleData(company),
    ]).pipe(
      switchMap(([_, [emps, hols, vacs, tz]]) => {
        state = getState();
        return this.workerService
          .generateSchedules(
            emps,
            hols,
            vacs,
            state.scheduleTemplate,
            tz,
            start,
            end
          )
          .pipe(
            tap((result: ScheduleDetail[]) => {
              state = getState();
              setState({
                ...state,
                generatedSchedule: result,
                holidays: hols,
                absences: vacs,
                employees: emps,
                timeZoneOffset: tz,
                initState: {
                  ...state.initState,
                  generatedSchedule: true,
                  employee: true,
                  start,
                  end,
                },
              });
            })
          );
      })
    );
  }

  @Action(GetBanksOfHours, { cancelUncompleted: true })
  getBanksOfHours({
    getState,
    setState,
  }: StateContext<TimeAndAttendanceStateModel>): Observable<
    EmployeeAbsenceBank[]
  > {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    if (state.bankInitialized) return of(state.banksOfHours);
    const employeeNo = this.store.selectSnapshot(AuthState.getEmployeeNo);
    const filter = `Employee_No eq '${employeeNo}'`;
    return this.timeAndAttendanceStoreService
      .fetchEmployeeAbsenceBanks(company, filter)
      .pipe(
        tap((result: EmployeeAbsenceBank[]) => {
          state = getState();
          setState({
            ...state,
            banksOfHours: result,
            bankInitialized: true,
          });
        })
      );
  }

  @Action(GetTaEmployees, { cancelUncompleted: true })
  getTaEmployees({
    getState,
    setState,
  }: StateContext<TimeAndAttendanceStateModel>): Observable<Employee[]> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    if (state.initState['employee']) return of(state.employees);
    return this.timeAndAttendanceStoreService.fetchTaEmployees(company).pipe(
      tap((result: Employee[]) => {
        state = getState();
        setState({
          ...state,
          employees: result,
          initState: {
            ...state.initState,
            employee: true,
          },
        });
      })
    );
  }

  @Action(AddAbsenceRequest)
  addAbsenceRequest(
    ctx: StateContext<TimeAndAttendanceStateModel>,
    { payload }: AddAbsenceRequest
  ): Observable<EmployeeAbsenceSched[]> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService
      .addAbsenceRequest(company, payload)
      .pipe(
        tap(() => {
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'breaks',
          });
        })
      );
  }

  @Action(UpdateAbsenceRequest)
  updateAbsenceRequest(
    ctx: StateContext<TimeAndAttendanceStateModel>,
    { payload }: UpdateAbsenceRequest
  ) {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService
      .updateAbsenceRequest(company, payload)
      .pipe(
        tap(() => {
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'breaks',
          });
        })
      );
  }

  @Action(AddTimeSheetHeader)
  addTimeSheetHeader(
    { getState, setState }: StateContext<TimeAndAttendanceStateModel>,
    { payload }: AddTimeSheetHeader
  ): Observable<TimesheetHeader> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService
      .postTimeSheetHeader(payload, company)
      .pipe(
        tap((result) => {
          const state = getState();
          const x = { ...result, no: result.no };
          setState({
            ...state,
            timesheetHeaders: [...state.timesheetHeaders, x],
            selectedTimesheetHeader: x,
          });

          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'timeSheetHeaders',
          });
        })
      );
  }

  @Action(AddTaEmployee)
  addTaEmployee(
    ctx: StateContext<TimeAndAttendanceStateModel>,
    { payload }: AddTaEmployee
  ): Observable<Employee> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService
      .addTaEmployee(company, payload)
      .pipe(
        tap(() => {
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'employees',
          });
        })
      );
  }

  @Action(PostJournalLines)
  postJournalLines(
    { getState, setState }: StateContext<TimeAndAttendanceStateModel>,
    { payload, groupNo, postingDate }: PostJournalLines
  ): Observable<TimesheetHeaderPostReturn> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService
      .postJournalLines(payload, groupNo, postingDate, company)
      .pipe(
        tap((result) => {
          if (!result) throw new Error('Business central result is invalid');
          const state = getState();
          setState({
            ...state,
          });
        })
      );
  }

  @Action(GetTimeSheetHeaders)
  getTimeSheetHeaders({
    getState,
    setState,
  }: StateContext<TimeAndAttendanceStateModel>): Observable<TimesheetHeader[]> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService.getTimeSheetHeaders(company).pipe(
      tap((result) => {
        if (!result) throw new Error('Business central result is invalid');
        const state = getState();
        setState({
          ...state,
          timesheetHeaders: result,
        });
      })
    );
  }

  @Action(GetTechTimeSheetHeaders)
  getTechTimeSheetHeaders({
    getState,
    setState,
  }: StateContext<TimeAndAttendanceStateModel>): Observable<TimesheetHeader[]> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    const employee = this.store.selectSnapshot(AuthState.getEmployeeNo);
    const filter = `Employee_No eq '${employee}'`;
    return this.timeAndAttendanceStoreService
      .getTimeSheetHeaders(company, filter)
      .pipe(
        tap((result) => {
          if (!result) throw new Error('Business central result is invalid');
          const state = getState();
          const filterTsHeaders = result.filter(
            (x) => x.employee_No !== employee
          );
          setState({
            ...state,
            timesheetHeaders: [...filterTsHeaders, ...result],
          });
        })
      );
  }

  @Action(BatchUpdateAbsenceStatus)
  batchUpdateAbsenceStatus(
    ctx: StateContext<TimeAndAttendanceStateModel>,
    { payload }: BatchUpdateAbsenceStatus
  ): Observable<EmployeeAbsenceSched[]> {
    if (!this.store.selectSnapshot(AuthState.isTa)) return of();

    const company = this.store.selectSnapshot(AuthState.company);
    return this.timeAndAttendanceStoreService
      .batchUpdateAbsences(payload, company)
      .pipe(
        tap(() => {
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'breaks',
          });
        })
      );
  }
}
