import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Region } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  regions: { items: Region[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class RegionsGQL extends Query<Response, GraphQLParams<'region'>> {
  document = gql(config.region.query);
}
