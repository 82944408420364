import { Injectable } from '@angular/core';
import type { Area, BinContent, GLAccount, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  glAccounts: { items: GLAccount[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class GlAccountsGQL extends Query<Response, GraphQLParams<'glAccount'>> {
  document = gql(config.glAccount.query);
}
