import { Injectable } from '@angular/core';
import type { GraphQLParams, ProblemCode } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  problemCodes: { items: ProblemCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class ProblemCodesGQL extends Query<
  Response,
  GraphQLParams<'problemCode'>
> {
  document = gql(config.problemCode.query);
}
