import { Injectable } from '@angular/core';
import type { Area, BinContent, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  binContents: { items: BinContent[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class BinsGQL extends Query<Response, GraphQLParams<'binContent'> > {
  document = gql(config.binContent.query);
}
