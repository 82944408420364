import { Injectable } from '@angular/core';
import type { GraphQLParams, UniversalDocumentNo } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  universalDocumentNos: { items: UniversalDocumentNo[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class UniversalDocumentNosGQL extends Query<
  Response,
  GraphQLParams<'universalDocumentNo'>
> {
  document = gql(config.universalDocumentNo.query);
}
