import { Operation } from 'fast-json-patch';

import { RequirementStateObject } from '@stores-states/requirement.state';

/**
 * Add Requirement
 *
 * @param payload Requirement object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class AddRequirement {
  static readonly type = '[Requirement] Add';

  constructor(public payload: RequirementStateObject) {}
}

/**
 * Get all Requirements By Source
 *
 * @param sourceNo Source Document No
 * @param sourceLineNo Source Document line no if any or 0.
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetRequirementsBySource {
  static readonly type = '[Requirement] Get By Source';

  constructor(public sourceNo: string, public sourceLineNo?: number) {}
}

/**
 * Get a single Requirement
 *
 * @param documentType Requirement document type (Consummable, Item, OSP, etc.)
 * @param sourceNo Source Document No
 * @param sourceLineNo Source Document line no if any or 0.
 * @param lineNo Requirement line no
 */
export class GetRequirement {
  static readonly type = '[Requirement] GetSingle';

  constructor(public documentType: string, public sourceNo: string, public sourceLineNo: number, public lineNo: number) {}
}

/**
 * Update Requirement, this method will dynamically update the cache Requirement list and selected Requirement.
 *
 * @param id The store generated identifier.
 * @param patch JSON patch object used to update the Requirement. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateRequirement {
  static readonly type = '[Requirement] Update';

  constructor(public id: string, public patch: Operation[]) {}
}

/**
 * Delete Requirement, this method will dynamically update the cache Requirement list and set selected Requirement to null.
 *
 * @param id The store generated identifier.
 */
export class DeleteRequirement {
  static readonly type = '[Requirement] Delete';

  constructor(public id: string) {}
}

/**
 * Remove Requirements , this method will only remove requirements from local chache without communication with remote server.
 *
 * @param workOrderNo  Work Order No.
 * @param LineNo Line No.
 */
export class RemoveCachedRequirements {
  static readonly type = '[Requirement] Remove Cached';

  constructor(public workOrderNo: string, public lineNo?: number) {}
}
