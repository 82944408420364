import { Injectable } from '@angular/core';
import type { GraphQLParams, ReasonCode } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  reasonCodes: { items: ReasonCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class ReasonCodesGQL extends Query<
  Response,
  GraphQLParams<'reasonCode'>
> {
  document = gql(config.reasonCode.query);
}
