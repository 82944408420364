import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TabHeadersService {
  cachedItems = new Map();
  getKey(webServiceName: string): string[] {
    return [];
  }

  getHeader(webServiceName: string): string[] {
    switch (webServiceName) {
      case 'FWOlineHistory':
        return [
          'workOrderNo',
          'equipmentId',
          'stepNo',
          'description',
          'startingDate',
          'closedBy',
        ];
      case 'Lines':
        return [
          'lineNo',
          'equipmentId',
          'workProcedureStep',
          'technicianCode',
          'estimatedTime',
          'finished',
          'standardTime',
          'equipmentDescription',
          'startingDate',
          'startingTime',
          'endingDate',
          'endingTime',
        ];

      case 'AdditionalDescriptions':
        return [
          'description',
          'actualTime',
          'technicianCode',
          'date',
          'createdDateTime',
        ];

      case 'Comments':
        return ['description', 'actualTime', 'technicianCode', 'date'];

      case 'webWorkOrderHeaderFeedback':
        return ['createdBy', 'date', 'feedbackType', 'feedback'];

      case 'RequiredQualifiLine':
        return ['type', 'description', 'code'];

      case 'WebExpectedRelatedReq':
        return [
          'type',
          'no',
          'description',
          'expectedQuantity',
          'actualQuantity',
          'postedQuantity',
          'locationCode',
          'projAvailBalance',
        ];

      case 'TagWEBtimesheet':
        return [
          'startingDatetime',
          'actualTime',
          'endingDatetime',
          'technicianCode',
          'sourceWorkOrderNo',
          'sourceWorkOrderLine',
        ];

      case 'FinishedWOLineWS':
        return [
          'startingDate',
          'startingTime',
          'actualTime',
          'endingDate',
          'endingTime',
          'technicianCode',
          'workOrderNo',
        ];
      default:
        return [];
    }
  }
}
