import { Injectable } from '@angular/core';
import type { GraphQLParams, Location } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  locations: { items: Location[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class LocationsGQL extends Query<Response, GraphQLParams<'location'>> {
  document = gql(config.location.query);
}
