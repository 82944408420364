import { Injectable } from '@angular/core';
import type { GraphQLParams, Tool, UnitOfMeasure } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  unitOfMeasures: { items: UnitOfMeasure[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class UnitOfMeasuresGQL extends Query<
  Response,
  GraphQLParams<'unitOfMeasure'>
> {
  document = gql(config.unitOfMeasure.query);
}
