import { Injectable } from '@angular/core';
import type { GraphQLParams, PersonnelGroup } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  personnelGroups: { items: PersonnelGroup[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class PersonnelGroupsGQL extends Query<
  Response,
  GraphQLParams<'personnelGroup'>
> {
  document = gql(config.personnelGroup.query);
}
