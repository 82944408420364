import { Injectable } from '@angular/core';
import { config, FormStatus } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import {} from '@tag/graphql';

export interface Response {
  formStatuses: { items: FormStatus[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class FormStatusGQL extends Query<Response> {
  document = gql(config.formStatus.query);
}
