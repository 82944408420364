import { Injectable } from '@angular/core';
import type { Area, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  areas: { items: Area[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class AreasGQL extends Query<Response, GraphQLParams<'area'>> {
  document = gql(config.area.query);
}
