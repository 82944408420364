import { Injectable } from '@angular/core';
import type { EquipmentFailureCode, GraphQLParams, Tool } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  equipmentFailureCodes: { items: EquipmentFailureCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class FailureCodesGQL extends Query<
  Response,
  GraphQLParams<'equipmentFailureCode'>
> {
  document = gql(config.equipmentFailureCode.query);
}
