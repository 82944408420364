import { Injectable } from '@angular/core';
import type { GraphQLParams, ReasonCode, StatusCode } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  statusCodes: { items: StatusCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class StatusCodesGQL extends Query<
  Response,
  GraphQLParams<'statusCode'>
> {
  document = gql(config.statusCode.query);
}
