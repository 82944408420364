import type { WorkOrderLine } from '@tag/graphql';
import {
  BatchWorkOrderLine,
  ReportMaintenance,
  WorkOrderDocumentType,
} from '@api/types';
import { Operation } from 'fast-json-patch';

/**
 * Add Work Order Line
 *
 * @param payload Work Order Line object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class AddWorkOrderLine {
  static readonly type = '[WorkOrderLine] Add';

  constructor(
    public payload: WorkOrderLine,
    public selectedItem?: WorkOrderLine | null
  ) {}
}

/**
 * Get all Work Order Lines
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetWorkOrderLines {
  static readonly type = '[WorkOrderLine] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get all Work Order Line
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetWorkOrderLine {
  static readonly type = '[WorkOrderLine] Get One';

  constructor(
    public workOrderNo: string,
    public lineNo: number,
    public bustCache?: boolean
  ) {}
}

/**
 * Get Planned Work Order Lines
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetPlannedWorkOrderLines {
  static readonly type = '[WorkOrderLine] Get Planned';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get summary work order lines
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetSummaryWorkOrderLines {
  static readonly type = '[WorkOrderLine] Get Summary';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get paginated Work Order Lines
 *
 * @param filter OData filter
 */
export class GetPaginatedWorkOrderLines {
  static readonly type = '[WorkOrderLine] Get Paginated';

  constructor(
    public type: 'released' | 'planned' | 'finished',
    public top?: number,
    public skip?: number,
    public filter?: string,
    public search?: string
  ) {}
}

/**
 * Get paginated Work Order Lines
 *
 * @param filter OData filter
 */
export class GetCalendarWorkOrderLines {
  static readonly type = '[WorkOrderLine] Get Calendar';

  constructor(public start: Date, public end: Date) {}
}

/**
 * Get work order lines
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetWorkOrderLinesByWorkOrder {
  static readonly type = '[WorkOrderLine] Get By workOrder';

  constructor(
    public workOrderNo: string,
    public documentType: WorkOrderDocumentType = WorkOrderDocumentType.released
  ) {}
}

/**
 * Update Work Order Line, this method will dynamically update the cache Work Order Line list and selected Work Order Line.
 *
 * @param no Work Order No
 * @param line Work Order Line No
 * @param patch JSON patch object used to update the Work Order Line. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 * @param dontShowSuccessMessage Do not show the success message.
 */
export class UpdateWorkOrderLine {
  static readonly type = '[WorkOrderLine] Update';

  constructor(
    public no: string,
    public line: number,
    public patch: Operation[],
    public selectedItem?: WorkOrderLine | null,
    public dontShowSuccessMessage?: boolean
  ) {}
}

/**
 * Update Work Order Line, this method will dynamically update the cache Work Order Line list and selected Work Order Line.
 *
 * @param payload Report maintenance object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 * @param dontShowSuccessMessage Do not show the success message.
 */
export class ReportWorkOrderLineMaintenance {
  static readonly type = '[WorkOrderLine] Report Maintenance';

  constructor(
    public payload: ReportMaintenance,
    public selectedItem?: WorkOrderLine | null,
    public dontShowSuccessMessage?: boolean
  ) {}
}

/**
 * Batch add multiple Work Order Lines
 *
 * @param payload Array of WorkOrderLinePatch Objects
 */
export class UpdateWorkOrderLines {
  static readonly type = '[WorkOrderLine] Update Multiple';

  constructor(public payload: BatchWorkOrderLine) {}
}

/**
 * Delete Work Order Line, this method will dynamically update the cache Work Order Line list and set selected Work Order Line to null.
 *
 * @param no Work Order No
 * @param line Work Order Line No
 */
export class DeleteWorkOrderLine {
  static readonly type = '[WorkOrderLine] Delete';

  constructor(public no: string, public line: number) {}
}

/**
 * Batch add multiple Work Order Lines
 *
 * @param payload Array of Work Order Line
 */
export class DeleteWorkOrderLines {
  static readonly type = '[WorkOrderLine] Delete Multiple';

  constructor(public payload: WorkOrderLine[]) {}
}

/**
 * Set selected Work Order Line
 *
 * @param payload New Work Order Line full object. Summary is not supported.
 */
export class SetSelectedWorkOrderLine {
  static readonly type = '[WorkOrderLine] Set';

  constructor(public payload: WorkOrderLine | null) {}
}

/**
 * Set multiple selected Work Order Line
 *
 * @param payload List of new Work Order Line full object. Summary is not supported.
 */
export class SetSelectedWorkOrderLines {
  static readonly type = '[WorkOrderLine] Set Multiple';

  constructor(public payload: WorkOrderLine[]) {}
}
