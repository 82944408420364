/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import type {
  FinishedWorkOrderLine,
  WorkOrder,
  WorkOrderLine,
} from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { Observable, from, of } from 'rxjs';
import { finalize, mergeAll, tap } from 'rxjs/operators';

import { ApolloService } from '@shared/apollo/apollo.service';
import { RemoveCachedFeedbacks } from '@stores-actions/feedback.action';
import { AddNotification } from '@stores-actions/notification.action';
import { RemoveCachedRequirements } from '@stores-actions/requirement.action';
import {
  AddWorkOrderLine,
  DeleteWorkOrderLine,
  DeleteWorkOrderLines,
  GetCalendarWorkOrderLines,
  GetPaginatedWorkOrderLines,
  GetPlannedWorkOrderLines,
  GetSummaryWorkOrderLines,
  GetWorkOrderLine,
  GetWorkOrderLines,
  GetWorkOrderLinesByWorkOrder,
  ReportWorkOrderLineMaintenance,
  SetSelectedWorkOrderLine,
  SetSelectedWorkOrderLines,
  UpdateWorkOrderLine,
  UpdateWorkOrderLines,
} from '@stores-actions/work-order-line.action';
import { Paginated } from '@stores-models/paginated';
import {
  BatchWolReturn,
  WorkOrderLineStoreService,
} from '@stores-services/work-order-line-store.service';

import { AuthState } from './authentication.state';
import { FeedbackStateObject } from './feedback.state';
import { RequirementStateObject } from './requirement.state';
import { ReportMaintenanceReturn, WorkOrderDocumentType } from '@api/types';

export class WorkOrderLineStateModel {
  workOrderLines: WorkOrderLine[] = [];
  plannedWorkOrderLines: WorkOrderLine[] = [];
  calendarWorkOrderLines: WorkOrderLine[] = [];
  selectedWorkOrderLine!: WorkOrderLine | null;
  selectedWorkOrderLines: WorkOrderLine[] = [];
  initialized = false;
  paginatedWorkOrderLines: Paginated<WorkOrderLine>[] = [];
}

/**
 * Work Order Lines metadata and action mappings.
 */
@State<WorkOrderLineStateModel>({
  name: 'workOrderLine',
  defaults: {
    workOrderLines: [],
    paginatedWorkOrderLines: [],
    calendarWorkOrderLines: [],
    plannedWorkOrderLines: [],
    selectedWorkOrderLine: null,
    selectedWorkOrderLines: [],
    initialized: false,
  },
})
@Injectable()
export class WorkOrderLineState {
  constructor(
    private workOrderLineStoreService: WorkOrderLineStoreService,
    private readonly store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo,
    private apolloUtils: ApolloService
  ) {}

  static getWorkOrderLine(
    workOrderNo: string,
    lineNo: number
  ): (state: WorkOrderLineStateModel) => WorkOrderLine {
    return createSelector(
      [WorkOrderLineState],
      (state: WorkOrderLineStateModel) =>
        state.workOrderLines.filter(
          (wol) => wol.workOrderNo === workOrderNo && wol.lineNo === lineNo
        )[0]
    );
  }

  static getWorkOrderLinesByWorkOrder(
    no: string,
    documentType: WorkOrderDocumentType = WorkOrderDocumentType.released
  ): (state: WorkOrderLineStateModel) => WorkOrderLine[] {
    return createSelector(
      [WorkOrderLineState],
      (state: WorkOrderLineStateModel) =>
        documentType === 'Planned'
          ? state.plannedWorkOrderLines.filter((wol) => wol.workOrderNo === no)
          : state.workOrderLines.filter((wol) => wol.workOrderNo === no)
    );
  }

  static getWorkOrderLinesByArea(
    no: string
  ): (state: WorkOrderLineStateModel) => WorkOrderLine[] {
    return createSelector(
      [WorkOrderLineState],
      (state: WorkOrderLineStateModel) =>
        state.workOrderLines.filter((wol) => wol.area === no)
    );
  }

  static getWorkOrderLinesByTechnician(
    no: string
  ): (state: WorkOrderLineStateModel) => WorkOrderLine[] {
    return createSelector(
      [WorkOrderLineState],
      (state: WorkOrderLineStateModel) =>
        state.workOrderLines.filter((wol) => wol.technicianCode === no)
    );
  }

  static getWorkOrderLinesByEquipment(
    id: string
  ): (state: WorkOrderLineStateModel) => WorkOrderLine[] {
    return createSelector(
      [WorkOrderLineState],
      (state: WorkOrderLineStateModel) =>
        state.workOrderLines.filter((wol) => wol.equipmentId === id)
    );
  }

  static getWorkOrderLinesByPagination(
    type: 'released' | 'planned' | 'finished',
    top: number,
    skip: number,
    filter?: string,
    search?: string
  ): (state: WorkOrderLineStateModel) => Paginated<WorkOrderLine> | undefined {
    return createSelector(
      [WorkOrderLineState],
      (state: WorkOrderLineStateModel) =>
        state.paginatedWorkOrderLines.find(
          (wol) =>
            wol.top === top &&
            wol.skip === skip &&
            wol.filter === filter &&
            wol.custom === type &&
            wol.search === search
        )
    );
  }

  @Selector()
  static getWorkOrderLines(state: WorkOrderLineStateModel): WorkOrderLine[] {
    return state.workOrderLines.filter((wol) => !wol.approvalPending);
  }

  @Selector()
  static getCalendarWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.calendarWorkOrderLines;
  }

  @Selector()
  static getPlannedWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.plannedWorkOrderLines;
  }

  @Selector()
  static getAllWorkOrderLines(state: WorkOrderLineStateModel): WorkOrderLine[] {
    return state.workOrderLines;
  }

  @Selector()
  static getSelectedWorkOrderLine(
    state: WorkOrderLineStateModel
  ): WorkOrderLine | null {
    return state.selectedWorkOrderLine;
  }

  @Selector()
  static getSelectedWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.selectedWorkOrderLines;
  }

  @Selector()
  static getReviewWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.workOrderLines.filter(
      (s) => s.approvalPending === false && s.pendingRelease === true
    );
  }

  @Selector()
  static getUnscheduledWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.workOrderLines.filter(
      (wol) => !wol.startingDatetime || !wol.endingDatetime
    );
  }
  @Selector()
  static getUnassignedWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.workOrderLines.filter((wol) => !wol.technicianCode);
  }
  @Selector()
  static getTsUnassignedWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.workOrderLines.filter(
      (wol) =>
        wol.recordedTechnicianCount === 0 ||
        (wol.recordedTechnicianCount ?? 0) >= (wol.plannedTechnicianCount ?? 0)
    );
  }

  @Selector()
  static getTimeSheetUnscheduledWorkOrderLines(
    state: WorkOrderLineStateModel
  ): WorkOrderLine[] {
    return state.workOrderLines.filter(
      (wol) =>
        (wol.estimatedTime || 0) >
        (wol.recordedTimeQty ?? 0) + (wol.postedTimeQty ?? 0)
    );
  }

  @Selector()
  static getInitStatus(state: WorkOrderLineStateModel): boolean {
    return state.initialized;
  }

  @Action(GetWorkOrderLines, { cancelUncompleted: true })
  getWorkOrderLines(
    { patchState }: StateContext<WorkOrderLineStateModel>,
    { filter }: GetWorkOrderLines
  ): Observable<WorkOrderLine[]> {
    return this.workOrderLineStoreService.fetchWorkOrderLines(filter).pipe(
      tap((result) => {
        patchState({
          workOrderLines: result,
        });
      })
    );
  }

  @Action(GetPaginatedWorkOrderLines, { cancelUncompleted: true })
  getPaginatedWorkOrderLines(
    { patchState, getState }: StateContext<WorkOrderLineStateModel>,
    { type, filter, top, skip, search }: GetPaginatedWorkOrderLines
  ): Observable<Paginated<any>> {
    let obs$: Observable<any>;
    switch (type) {
      case 'planned':
        obs$ =
          this.workOrderLineStoreService.fetchPaginatedPlannedWorkOrderLines(
            top,
            skip,
            filter,
            search
          );
        break;
      case 'finished':
        obs$ =
          this.workOrderLineStoreService.fetchPaginatedFinishedWorkOrderLines(
            top,
            skip,
            filter,
            search
          );
        break;

      default:
        obs$ = this.workOrderLineStoreService.fetchPaginatedWorkOrderLines(
          top,
          skip,
          filter,
          search
        );
        break;
    }

    return obs$.pipe(
      tap((result) => {
        const state = getState();
        const paginatedWorkOrderLines = [...state.paginatedWorkOrderLines];

        const index = paginatedWorkOrderLines.findIndex(
          (req) =>
            req.top === top &&
            req.skip === skip &&
            req.filter === filter &&
            req.custom === type
        );
        if (index === -1) paginatedWorkOrderLines.push(result);
        else paginatedWorkOrderLines[index] = result;

        patchState({
          paginatedWorkOrderLines,
        });
      })
    );
  }

  @Action(GetCalendarWorkOrderLines, { cancelUncompleted: true })
  getCalendarWorkOrderLines(
    { patchState }: StateContext<WorkOrderLineStateModel>,
    { start, end }: GetCalendarWorkOrderLines
  ): Observable<WorkOrderLine[]> {
    const filter = `(Starting_Datetime ge ${start.toISOString()}) and (Starting_Datetime le ${end.toISOString()})`;
    return this.workOrderLineStoreService.fetchWorkOrderLines(filter).pipe(
      tap((result) => {
        patchState({
          calendarWorkOrderLines: result,
        });
      })
    );
  }

  @Action(GetSummaryWorkOrderLines, { cancelUncompleted: true })
  getSummaryWorkOrderLines(
    { patchState }: StateContext<WorkOrderLineStateModel>,
    { filter, bustCache }: GetSummaryWorkOrderLines
  ): Observable<WorkOrderLine[]> {
    return this.workOrderLineStoreService.fetchWorkOrderLines(filter).pipe(
      tap((result) => {
        patchState({
          workOrderLines: result,
        });
      })
    );
  }

  @Action(GetPlannedWorkOrderLines, { cancelUncompleted: true })
  getPlannedWorkOrderLines(
    { patchState }: StateContext<WorkOrderLineStateModel>,
    { filter }: GetPlannedWorkOrderLines
  ): Observable<WorkOrderLine[]> {
    return this.workOrderLineStoreService
      .fetchPlannedWorkOrderLines(filter)
      .pipe(
        tap((result) => {
          patchState({
            plannedWorkOrderLines: result,
          });
        })
      );
  }

  @Action(GetWorkOrderLine)
  getWorkOrderLine(
    { getState, setState }: StateContext<WorkOrderLineStateModel>,
    { workOrderNo, lineNo, bustCache }: GetWorkOrderLine
  ): Observable<WorkOrderLine[]> {
    const filter = `Work_Order_No eq '${workOrderNo}' and Line_No eq ${lineNo}`;
    return this.workOrderLineStoreService.fetchWorkOrderLines(filter).pipe(
      tap((result) => {
        if (result.length === 0) return;
        const state = getState();
        const wol = result[0];

        const workOrderLineList = [...state.workOrderLines];
        const i = workOrderLineList.findIndex(
          (item) => item.workOrderNo + item.lineNo === workOrderNo + lineNo
        );
        if (~i) workOrderLineList[i] = wol;
        else workOrderLineList.push(wol);

        setState({
          ...state,
          workOrderLines: workOrderLineList,
          selectedWorkOrderLine: wol,
        });
      })
    );
  }

  @Action(GetWorkOrderLinesByWorkOrder, { cancelUncompleted: true })
  getWorkOrderLinesByWorkOrder(
    { getState, setState }: StateContext<WorkOrderLineStateModel>,
    { workOrderNo, documentType }: GetWorkOrderLinesByWorkOrder
  ): Observable<WorkOrderLine[]> {
    const filter = `Work_Order_No eq '${workOrderNo}'`;

    const getObs$ =
      documentType === 'Planned'
        ? this.workOrderLineStoreService.fetchPlannedWorkOrderLines(filter)
        : this.workOrderLineStoreService.fetchWorkOrderLines(filter);

    return getObs$.pipe(
      tap((result) => {
        const state = getState();
        let workOrderLineList =
          documentType === 'Planned'
            ? [...state.plannedWorkOrderLines]
            : [...state.workOrderLines];
        workOrderLineList = workOrderLineList.filter(
          (wol) => wol.workOrderNo !== workOrderNo
        );

        setState({
          ...state,
          plannedWorkOrderLines:
            documentType === 'Planned'
              ? [...workOrderLineList, ...result]
              : [...state.plannedWorkOrderLines],
          workOrderLines:
            documentType === 'Planned'
              ? [...state.workOrderLines]
              : [...workOrderLineList, ...result],
        });
      })
    );
  }

  @Action(AddWorkOrderLine)
  addWorkOrderLine(
    ctx: StateContext<WorkOrderLineStateModel>,
    { payload }: AddWorkOrderLine
  ): Observable<WorkOrderLine> {
    return this.workOrderLineStoreService.addWorkOrderLine(payload).pipe(
      tap((result) => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderLineNoLineWasCreatedSuccessfullyKeyParam',
          {
            id: result.workOrderNo,
            line: result.lineNo,
          }
        );
        const title = this.translocoService.translate('createKey');

        this.store.dispatch(new AddNotification(message, 'success', title));
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrderLines',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });
      })
    );
  }

  @Action(UpdateWorkOrderLine)
  updateWorkOrderLine(
    ctx: StateContext<WorkOrderLineStateModel>,
    { patch, no, line, dontShowSuccessMessage }: UpdateWorkOrderLine
  ): Observable<WorkOrderLine> {
    return this.workOrderLineStoreService
      .updateWorkOrderLine(no, line, patch)
      .pipe(
        tap((result) => {
          const message = this.translocoService.translate(
            'notificationsMessagesKeys.workOrderLineNoLineWasUpdatedSuccessfullyKeyParam',
            {
              id: no,
              line,
            }
          );
          const title = this.translocoService.translate('updatedKey');

          if (!dontShowSuccessMessage)
            this.store.dispatch(new AddNotification(message, 'success', title));

          this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrderLine>(
            'workOrderLines',
            {
              workOrderNo: no,
              lineNo: line,
            }
          );
          this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>(
            'workOrders',
            {
              no,
            }
          );
        })
      );
  }

  @Action(UpdateWorkOrderLines)
  updateWorkOrderLines(
    { dispatch, patchState }: StateContext<WorkOrderLineStateModel>,
    { payload }: UpdateWorkOrderLines
  ): Observable<BatchWolReturn> {
    return this.workOrderLineStoreService.addWorkOrderLines(payload).pipe(
      tap((res) => {
        const message = this.translocoService.translate(
          'workOrderAndLinesWereSuccessfullyUpdatedKey'
        );
        const title = this.translocoService.translate('updatedKey');

        dispatch(new AddNotification(message, 'success', title));

        patchState({
          selectedWorkOrderLine: res.workOrderLines[0],
          selectedWorkOrderLines: res.workOrderLines,
        });

        if (res.workOrderLines.length > 0) {
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'workOrderLines',
          });
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'plannedWorkOrderLines',
          });
        }
        this.apolloUtils.pessimisticCustomCacheBatchUpdate<WorkOrder>(
          'workOrders',
          res.workOrderLines.map((wol) => ({ no: wol.workOrderNo }))
        );
        this.apolloUtils.pessimisticCustomCacheBatchUpdate<WorkOrder>(
          'plannedWorkOrders',
          res.workOrderLines.map((wol) => ({ no: wol.workOrderNo }))
        );

        // if there are new feedbacks, should fetch them
        if (res.workOrderLines.some((wol) => wol.feedback))
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'feedbacks',
          });

        // if work order line is not deleted, only feedback are deleted, we can directly remove feedback from cache
        if (res.workOrderLines.length > 0) {
          res.workOrderLines.forEach((wol) =>
            this.store.dispatch(
              new RemoveCachedFeedbacks(wol.workOrderNo, wol.lineNo)
            )
          );
        }

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requirements',
        });
      })
    );
  }

  @Action(DeleteWorkOrderLine)
  deleteWorkOrderLine(
    ctx: StateContext<WorkOrderLineStateModel>,
    { no, line }: DeleteWorkOrderLine
  ): Observable<WorkOrderLine> {
    return this.workOrderLineStoreService.deleteWorkOrderLine(no, line).pipe(
      tap(() => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderLineNoLineWasDeletedSuccessfullyKeyParam',
          {
            id: no,
            line,
          }
        );
        const title = this.translocoService.translate('deletedKey');
        this.store.dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrderLines',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });
        this.store.dispatch(new RemoveCachedFeedbacks(no, line));
        this.store.dispatch(new RemoveCachedRequirements(no, line));
      })
    );
  }

  @Action(DeleteWorkOrderLines)
  deleteWorkOrderLines(
    ctx: StateContext<WorkOrderLineStateModel>,
    { payload }: DeleteWorkOrderLines
  ): Observable<WorkOrderLine | null> {
    const obs$: Observable<WorkOrderLine>[] = [];
    if (!payload || payload.length <= 0) return of(null);
    payload.forEach((wol) => {
      if (!wol.workOrderNo && !wol.lineNo) return;
      obs$.push(
        this.workOrderLineStoreService.deleteWorkOrderLine(
          wol.workOrderNo,
          wol.lineNo
        )
      );
    });
    return from(obs$).pipe(
      mergeAll(),
      finalize(() => {
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrderLines',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });

        // remove cached feedbacks and requirements
        payload.forEach((wol) => {
          if (!wol.workOrderNo && !wol.lineNo) return;
          this.store.dispatch(
            new RemoveCachedFeedbacks(wol.workOrderNo, wol.lineNo)
          );
          this.store.dispatch(
            new RemoveCachedRequirements(wol.workOrderNo, wol.lineNo)
          );
        });
      })
    );
  }

  @Action(ReportWorkOrderLineMaintenance)
  reportWorkOrderLineMaintenance(
    { getState, setState, dispatch }: StateContext<WorkOrderLineStateModel>,
    {
      payload,
      selectedItem,
      dontShowSuccessMessage,
    }: ReportWorkOrderLineMaintenance
  ): Observable<ReportMaintenanceReturn> {
    return this.workOrderLineStoreService
      .reportWorkOrderLineMaintenance(payload)
      .pipe(
        tap((result) => {
          const state = getState();
          const wol = result.workOrderLine;
          const workOrderLineList = [...state.workOrderLines];
          const workOrderLineIndex = workOrderLineList.findIndex(
            (item) =>
              item.workOrderNo + item.lineNo ===
              (wol?.workOrderNo ?? '') + wol?.lineNo
          );
          workOrderLineList[workOrderLineIndex] = {
            ...workOrderLineList[workOrderLineIndex],
            ...wol,
          };
          setState({
            ...state,
            workOrderLines: workOrderLineList,
            selectedWorkOrderLine:
              selectedItem !== undefined
                ? selectedItem
                : workOrderLineList[workOrderLineIndex],
          });

          const message = this.translocoService.translate(
            'notificationsMessagesKeys.workOrderLineNoLineWasUpdatedSuccessfullyKeyParam',
            {
              id: wol?.workOrderNo,
              line: wol?.lineNo,
            }
          );
          const title = this.translocoService.translate('updatedKey');
          const storePayload = [];
          if (!dontShowSuccessMessage)
            storePayload.push(new AddNotification(message, 'success', title));

          this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrderLine>(
            'workOrderLines',
            {
              workOrderNo: wol?.workOrderNo,
              lineNo: wol?.lineNo,
            }
          );
          this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>(
            'workOrders',
            {
              no: wol?.workOrderNo,
            }
          );
          this.apolloUtils.pessimisticCustomCacheUpdate<FeedbackStateObject>(
            'feedbacks',
            {
              documentNo: wol?.workOrderNo,
            }
          );
          this.apolloUtils.pessimisticCustomCacheUpdate<RequirementStateObject>(
            'requirements',
            {
              sourceNo: wol?.workOrderNo,
            }
          );

          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'finishedWorkOrders',
          });
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'timesheets',
          });
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'postedTimesheets',
          });

          // TODO - GraphQL - Refactor when the other stores are refactored
          dispatch(storePayload);
        })
      );
  }

  @Action(SetSelectedWorkOrderLine)
  setSelectedWorkOrderLine(
    { getState, setState }: StateContext<WorkOrderLineStateModel>,
    { payload }: SetSelectedWorkOrderLine
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedWorkOrderLine: payload,
    });
  }

  @Action(SetSelectedWorkOrderLines)
  setSelectedWorkOrderLines(
    { getState, setState }: StateContext<WorkOrderLineStateModel>,
    { payload }: SetSelectedWorkOrderLines
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedWorkOrderLines: payload,
    });
  }
}
