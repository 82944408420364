// @ts-nocheck
import { Injectable } from '@angular/core';
import {
  AbsenceRequestService,
  BatchEmployeeAbsenceRequestEdit,
  Employee,
  EmployeeAbsenceBank,
  EmployeeAbsenceBanksService,
  EmployeeAbsenceRequestEdit,
  EmployeeAbsenceRequestPost,
  EmployeeAbsenceSched,
  EmployeeAbsenceSchedsService,
  EmployeeGroupSched,
  EmployeeGroupSchedBreak,
  EmployeeGroupSchedBreaksService,
  EmployeeGroupSchedsService,
  EmployeeHolidaySched,
  EmployeeHolidaySchedsService,
  EmployeeList,
  EmployeeListsService,
  EmployeeScheduleLineDetail,
  EmployeeScheduleLinesService,
  EmployeesService,
  TimeZoneOffset,
  TimeZoneOffsetsService,
  TimesheetHeader,
  TimesheetHeaderPost,
  TimesheetHeaderPostReturn,
  TimesheetHeadersService,
  TimesheetJournal,
  TimesheetJournalLinePost,
  TimesheetJournalLinesService,
} from '@tag/graphql';
import {
  addDays,
  addMinutes,
  getHours,
  getMinutes,
  setDay,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

// import { Break } from '@stores-models/break';
import { ScheduleDetail } from '@stores-models/schedule-detail';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class TimeAndAttendanceStoreService {
  constructor() // private empAbsBank: EmployeeAbsenceBanksService, // private employeeGroupSchedsService: EmployeeGroupSchedsService, // private employeeScheduleLines: EmployeeScheduleLinesService,
  // private employeeGroupSchedBreaksService: EmployeeGroupSchedBreaksService,
  // private abs: AbsenceRequestService,
  // private timeSheetJournalService: TimesheetJournalLinesService,
  // private timeSheetHeadersService: TimesheetHeadersService,
  // private employeesService: EmployeesService,
  // private employeeListService: EmployeeListsService,
  // private holidaysService: EmployeeHolidaySchedsService,
  // private absencesService: EmployeeAbsenceSchedsService,
  // private tzService: TimeZoneOffsetsService
  {}

  /**
   * Fetchs employee schedule lines
   *
   * @param filter OData filter
   *
   * @returns EmployeeScheduleLineDetail
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchEmployeeSchedule(
    company: string,
    filter?: string
  ): Observable<EmployeeScheduleLineDetail[]> {
    return this.employeeScheduleLines
      .EmployeeScheduleLinesGet(company, filter)
      .pipe();
  }

  /**
   * Fetchs employee schedule lines
   *
   * @param filter OData filter
   *
   * @returns Employee[], EmployeeHolidaySched[], EmployeeAbsenceSched[]
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchEmployeeScheduleData(
    company: string,
    employeeNo?: string
  ): Observable<
    [
      EmployeeList[],
      EmployeeHolidaySched[],
      EmployeeAbsenceSched[],
      TimeZoneOffset[]
    ]
  > {
    const filter = employeeNo ? `EmployeeNo eq '${employeeNo}'` : '';
    const sFilter = employeeNo ? `employeeNo eq '${employeeNo}'` : '';
    return forkJoin([
      this.employeeListService.EmployeeListsGet(company, filter),
      this.holidaysService.EmployeeHolidaySchedsGet(company, sFilter),
      this.absencesService.EmployeeAbsenceSchedsGet(company, sFilter),
      this.tzService.TimeZoneOffsetsGet(company),
    ]).pipe(
      map(([emps, hols, abs, tz]) => {
        abs = this.groupAbsences(abs);
        return [emps, hols, abs, tz];
      })
    );
  }

  /**
   * Fetchs employees
   *
   * @param company
   * @returns employees
   */
  fetchTaEmployees(company: string): Observable<Employee[]> {
    return this.employeesService.EmployeesGet(company);
  }

  /**
   * Fetchs Employee banks of hours
   *
   * @param filter OData filter
   *
   * @returns EmployeeAbsenceBank
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchEmployeeAbsenceBanks(
    company: string,
    filter?: string
  ): Observable<EmployeeAbsenceBank[]> {
    return this.empAbsBank
      .EmployeeAbsenceBanksGet(company, filter)
      .pipe(map((x) => this.parseBanksOfHours(x)));
  }

  /**
   * Adds abscence request
   *
   * @param company
   * @param payload
   * @returns abscence request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addAbsenceRequest(
    company: string,
    payload: EmployeeAbsenceRequestPost
  ): Observable<EmployeeAbsenceSched[]> {
    return this.abs.AbsenceRequestPost(company, payload).pipe(
      map((r) => {
        if (r.absenceRequests) return this.groupAbsences(r.absenceRequests);
        return [];
      })
    );
  }

  /**
   * Adds ta employee
   *
   * @param company
   * @param payload
   * @returns ta employee
   */
  addTaEmployee(company: string, payload: Employee): Observable<Employee> {
    return this.employeesService.EmployeesPost(company, payload);
  }

  /**
   * Updates absence request
   *
   * @param company
   * @param payload
   * @returns absence request
   */
  updateAbsenceRequest(
    company: string,
    payload: EmployeeAbsenceRequestEdit
  ): Observable<string> {
    return this.abs.AbsenceRequestPut(company, payload);
  }

  /**
   * Get Timesheets Header (T&A)
   *
   * @param no
   * @param patch
   *
   * @returns time Sheet
   * @deprecated This is an internal implementation method, do not use directly.
   */
  getTimeSheetHeaders(
    company: string,
    filter?: string
  ): Observable<TimesheetHeader[]> {
    return this.timeSheetHeadersService.TimesheetHeadersGet(company, filter);
  }

  /**
   * Create Timesheets Header (T&A)
   *
   * @param payload
   *
   * @returns time Sheet header
   * @deprecated This is an internal implementation method, do not use directly.
   */
  postTimeSheetHeader(
    payload: TimesheetHeaderPost,
    company: string
  ): Observable<TimesheetHeader> {
    return this.timeSheetHeadersService
      .TimesheetHeadersPost(company, payload)
      .pipe(
        map(
          (th): TimesheetHeader => ({
            employeeNo: th.employeeNo,
            resourceNo: '',
            no: th.documentNo,
            timeSheetPeriod: th.timeSheetPeriod,
            startDate: th.startDate,
            endDate: th.endDate,
            status: th.status,
          })
        )
      );
  }

  /**
   * Update Timesheets Header (T&A)
   *
   * @param payload
   *
   * @returns time Sheet header
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateTimeSheetHeader(
    payload: TimesheetHeaderPost,
    company: string
  ): Observable<TimesheetHeaderPostReturn> {
    return this.timeSheetHeadersService.TimesheetHeadersPut(company, payload);
  }

  /**
   * Posts journal lines
   *
   * @param payload
   * @param group
   * @param posting
   * @param company
   * @returns journal lines
   */
  postJournalLines(
    payload: TimesheetJournal[],
    group: string,
    posting: Date,
    company: string
  ): Observable<TimesheetHeaderPostReturn> {
    const body: TimesheetJournalLinePost = {
      timesheetjournal: payload,
      employeeGroupCode: group,
      postingDate: posting,
    };

    return this.timeSheetJournalService.TimesheetJournalLinesPost(
      company,
      body
    );
  }

  /**
   * Batchs update absences
   *
   * @param payload
   * @param company
   * @returns update absences
   */
  batchUpdateAbsences(
    payload: BatchEmployeeAbsenceRequestEdit,
    company: string
  ): Observable<EmployeeAbsenceSched[]> {
    return this.abs.AbsenceRequestBatchPut(company, payload).pipe(
      map((r) => {
        if (r.absenceRequests) return this.groupAbsences(r.absenceRequests);
        return [];
      })
    );
  }

  /**
   * Gets generated schedule
   *
   * @param company
   * @returns generated schedule
   */
  getGeneratedSchedule(company: string): Observable<{
    schedule: ScheduleDetail[];
    calendarStartDate: Date | null;
  }> {
    return forkJoin([
      this.employeeGroupSchedsService.EmployeeGroupSchedsGet(company),
      this.employeeGroupSchedBreaksService.EmployeeGroupSchedBreaksGet(company),
    ]).pipe(
      map(([sch, breaks]) => ({
        schedule: this.generateTemplateSchedule(sch, breaks),
        calendarStartDate: sch[0]?.calendarStartDate ?? null,
      }))
    );
  }

  // TODO TA - This will not work with cross day schedule
  private generateTemplateSchedule(
    sch: EmployeeGroupSched[],
    breaks: EmployeeGroupSchedBreak[]
  ): ScheduleDetail[] {
    const genSch: ScheduleDetail[] = [];
    const gSch = new Map<string, EmployeeGroupSched[]>();
    const gBreaks = new Map<string, Break[]>();

    if (!sch[0].weeklyCandarStartDate) return [];

    const startDay = new Date(sch[0].weeklyCandarStartDate).getDay();

    sch.forEach((x) => {
      if (x.weeklyCalendarCode !== x.calendarCode || !x.employeeGroupCode)
        return;

      if (!gSch.has(x.employeeGroupCode)) gSch.set(x.employeeGroupCode, [x]);
      else gSch.get(x.employeeGroupCode)?.push(x);
    });

    breaks.forEach((x) => {
      if (x.weeklyCalendarCode !== x.calendarCode || !x.employeeGroupCode)
        return;
      if (!gBreaks.has(x.employeeGroupCode))
        gBreaks.set(x.employeeGroupCode, [new Break(x)]);
      else gBreaks.get(x.employeeGroupCode)?.push(new Break(x));
    });

    gSch.forEach((x, key) => {
      const date = startOfDay(startOfWeek(new Date()));
      const scheduleDate = setDay(date, startDay);

      x.forEach((y) => {
        const calDate = addDays(date, y.dayNumber ?? 0);
        if (!y.startTime || !y.endTime) return;

        const dayBreaks = gBreaks
          .get(key)
          ?.sort(
            (a, b) => a.startDateTime.getTime() - b.startDateTime.getTime()
          );

        const start = y.startTime.split(':');
        const startDate = new Date(
          calDate.getFullYear(),
          calDate.getMonth(),
          calDate.getDate(),
          +start[0],
          +start[1]
        );
        const endDate = addMinutes(startDate, y.totalDurationMinutes ?? 0);
        const ogEnd = new Date(endDate);

        if (
          dayBreaks &&
          dayBreaks.length > 0 &&
          y.dayType?.toUpperCase() !== 'NO DUTY'
        ) {
          //Break real Start/End
          dayBreaks.forEach((z, i) => {
            z.startDateTime = new Date(
              calDate.getFullYear(),
              calDate.getMonth(),
              calDate.getDate(),
              getHours(z.startDateTime),
              getMinutes(z.startDateTime)
            );

            // For night shift when break is on the next day
            if (z.startDateTime.getTime() < startDate.getTime())
              z.startDateTime = addDays(z.startDateTime, 1);

            z.endDateTime = addMinutes(z.startDateTime, z.durationMinutes ?? 0);

            if (
              z.startDateTime.getTime() > startDate.getTime() &&
              z.startDateTime.getTime() < endDate.getTime()
            ) {
              endDate.setTime(z.startDateTime.getTime());
            }
            if (
              z.endDateTime.getTime() > startDate.getTime() &&
              z.endDateTime.getTime() < endDate.getTime()
            ) {
              startDate.setTime(z.endDateTime.getTime());
            }

            // Normal Work
            const sDetail = new ScheduleDetail(
              startDate,
              endDate,
              scheduleDate
            );
            sDetail.fromEmployeeGroupSched(y);

            // Add break
            const bDetail = new ScheduleDetail(
              z.startDateTime,
              z.endDateTime,
              scheduleDate
            );
            bDetail.fromBreak(z);

            genSch.push(sDetail, bDetail);

            // If no more breaks fill the rest of the day.
            if (
              i === dayBreaks.length - 1 &&
              ogEnd.getTime() > endDate.getTime()
            ) {
              const eDetail = new ScheduleDetail(
                z.endDateTime,
                ogEnd,
                scheduleDate
              );
              eDetail.fromEmployeeGroupSched(y);
              genSch.push(eDetail);
            }
          });
        } else {
          const sDetail = new ScheduleDetail(startDate, endDate, scheduleDate);
          sDetail.fromEmployeeGroupSched(y);
          genSch.push(sDetail);
        }
      });
    });

    return genSch;
  }

  /**
   * Groups absences
   *
   * @param sch
   * @returns absences
   */
  private groupAbsences(sch: EmployeeAbsenceSched[]): EmployeeAbsenceSched[] {
    const schByDocNo: EmployeeAbsenceSched[] = [];

    sch
      .map((x) => ({
        ...x,
        scheduledStartDateTime: new Date(x.scheduledStartDateTime || ''),
        scheduledEndDateTime: new Date(x.scheduledEndDateTime || ''),
      }))
      .reduce((acc: any, curr) => {
        const docNo = curr.absenceNo ?? '';
        const i = schByDocNo.findIndex((x) => x.absenceNo === docNo);
        if (~i) {
          schByDocNo[i] = {
            ...curr,
            scheduledEndDateTime:
              (schByDocNo[i].scheduledEndDateTime?.getTime() || 0) >
              (curr.scheduledEndDateTime?.getTime() || 0)
                ? schByDocNo[i].scheduledEndDateTime
                : curr.scheduledEndDateTime,
            scheduledStartDateTime:
              (schByDocNo[i].scheduledStartDateTime?.getTime() || 0) <
              (curr.scheduledStartDateTime?.getTime() || 0)
                ? schByDocNo[i].scheduledStartDateTime
                : curr.scheduledStartDateTime,
          };
        } else {
          schByDocNo.push(curr);
        }
        return acc;
      }, {});

    return schByDocNo;
  }

  /**
   * Parses banks of hours
   *
   * @param banksOfHours
   * @returns banks of hours
   */
  private parseBanksOfHours(
    banksOfHours: EmployeeAbsenceBank[]
  ): EmployeeAbsenceBank[] {
    return banksOfHours.map((bank) => {
      const newBank: EmployeeAbsenceBank = {
        ...bank,
        vacation_Period_From: bank.vacation_Period_From
          ? this.bcDateParser(bank.vacation_Period_From)
          : undefined,
        vacation_Period_To: bank.vacation_Period_To
          ? this.bcDateParser(bank.vacation_Period_To)
          : undefined,
      };
      return newBank;
    });
  }

  /**
   * date parser
   *
   * @param date
   * @returns date parser
   */
  private bcDateParser(date: Date): Date | undefined {
    const rawDate = new Date(date);
    if (
      rawDate.getTime() < new Date('1970-01-01').getTime() ||
      date.toString() === '0001-01-01T00:00:00Z'
    )
      return undefined;
    return rawDate;
  }
}
